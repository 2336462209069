<template>
  <div id="appId">
    <div class="loading-wrapper" v-show="isLoading">
      <div class="loading-screen">
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
        <span class="loading-item"></span>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { getUniqueArray } from "@/utils";
import NotificationHelper from "@/helpers/notification.helper";
export default {
  name: "App",
  inject: ["socket", "receiveAudios"],
  data() {
    return {
      title: "Dashboard",
      agent_id: null,
      filter: {
        channel: false,
        business_unit: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/isLoading",
      agentRole: "accessibility/isAgent",

      internalChatRooms: "internalChat/getRooms",
      internalChatRoomGroups: "internalChat/getRoomGroups",
      notificationSound: "notificationSound",
      sortedRoomList: "chat/sortedRoomList",
    }),
    apiUrl() {
      return (
        import.meta.env.VITE_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/accessibility`
      );
    },
    url() {
      return this.$route.path.split("/");
    },
    accessibilityUrlV3() {
      return (
        import.meta.env.VITE_OMNICHANNEL_URL +
        `/api/${this.$store.getters.appId.hashed}/v3/accessibility`
      );
    },
    assignedChannel() {
      return this.$store.getters["agent/assignedChannel"];
    },

    userId() {
      return this.$store.state["localStorage"].decodedUserId;
    },
  },
  watch: {
    agentRole(val) {
      if (val) {
        this.agent_id = this.$store.getters.localStorage.decodedUserId;
      }
    },
  },
  async created() {
    await this.$store.dispatch("accessibility/getRoleV3");
    if (
      window.location.pathname.split("/")[1] != "auth" &&
      window.location.pathname.split("/")[1] != "workspace"
    ) {
      this.$store.dispatch("layout/changeLayoutType", {
        layoutType: "twocolumn",
      });
    }
    let Notification = new NotificationHelper({ vm: this });

    this.socket?.on("connect", () => {
      this.$store.commit("agent/SOCKET_STATUS", "Connected");
    });

    this.socket?.on("disconnect", () => {
      this.$store.commit("agent/SOCKET_STATUS", "Disconnected");
    });

    this.socket?.on("reconnect", () => {
      console.log("Socket Reconnect");
    });

    window.addEventListener("offline", () => {
      this.$store.commit("agent/SOCKET_STATUS", "Disconnected");
    });
    window.addEventListener("online", async () => {
      const roomSpec = JSON.parse(localStorage.getItem("filterSearch"));
      const response = await this.$store.dispatch("chat/getRoomList", roomSpec);

      await this.$store.dispatch(
        "chat/updateRoomList",
        getUniqueArray(response, "id")
      );

      const room = this.$store.getters["chat/selectedRoom"];
      if (room) {
        this.$store
          .dispatch("chat/selectRoom", {
            room: cloneDeep(room),
            senderId: this.$store.getters.localStorage.decodedUserId,
          })
          .then(() => {
            this.$store.commit("chat/SET_SCROLLING", true);
            this.$store.dispatch("chat/selectOnce", true);
          });
      }

      if (this.$store.getters.appId.decoded) {
        // this.$store.dispatch("getNotificationSound");

        this.socket?.emit("join-app", this.$store.getters.appId.decoded);

        this.socket?.emit("get-room", this.$store.getters.appId.hashed);
        this.socket?.on("get-room", (data) => {
          this.$store.commit("internalChat/SET_ROOMS", data);
        });

        this.getGroup();

        this.socket?.on("get-group", (data) => {
          this.$store.commit("internalChat/SET_ROOM_GROUPS", data);
        });

        this.socket?.on("ChangeGroupData", (data) => {
          if (data.userId.find((r) => r === this.userId)) {
            this.getGroup(true);
          }
        });

        this.socket?.on("message", async (data) => {
          this.$store.dispatch("internalChat/newMessage", data);
        });

        this.socket?.on("UserSeen", async (data) => {
          if (data.id == this.$route.params.ticketId) {
            this.$store.dispatch("ticket/getVisitingAgent", data.seen);
          }
        });

        this.socket?.on("UpdateStatusTicket", async (data) => {
          if (data.ticket == this.$route.params.ticketId) {
            this.$store.dispatch("ticket/updateStatusTicket", data);
            let email = this.$store.getters.localStorage.user.email;
            if (email !== data.user.email) {
              this.successToast(`Ticket has been update by ${data.user.name}`);
            }
          }
        });
      } else {
        this.socket?.emit("leave-app");
      }
    });

    window.addEventListener("focus", async () => {
      if (this.$route.name != "omni.conversation.chat") {
        return;
      }

      const room = this.$store.getters["chat/selectedRoom"];
      if (room) {
        await this.$store.dispatch("chat/selectRoom", {
          room: cloneDeep(room),
          senderId: this.$store.getters.localStorage.decodedUserId,
        });

        // this.$store.commit("chat/SET_SCROLLING", true);
        this.$store.dispatch("chat/selectOnce", true);
      }

      if (this.$store.getters.appId.decoded) {
        // this.$store.dispatch("getNotificationSound");

        this.socket?.emit("join-app", this.$store.getters.appId.decoded);

        this.socket?.emit("get-room", this.$store.getters.appId.hashed);
        this.socket?.on("get-room", (data) => {
          this.$store.commit("internalChat/SET_ROOMS", data);
        });

        this.getGroup();

        this.socket?.on("get-group", (data) => {
          this.$store.commit("internalChat/SET_ROOM_GROUPS", data);
        });

        this.socket?.on("ChangeGroupData", (data) => {
          if (data.userId.find((r) => r === this.userId)) {
            this.getGroup(true);
          }
        });

        this.socket?.on("message", async (data) => {
          this.$store.dispatch("internalChat/newMessage", data);
        });

        this.socket?.on("UserSeen", async (data) => {
          if (data.id == this.$route.params.ticketId) {
            this.$store.dispatch("ticket/getVisitingAgent", data.seen);
          }
        });

        this.socket?.on("UpdateStatusTicket", async (data) => {
          if (data.ticket == this.$route.params.ticketId) {
            this.$store.dispatch("ticket/updateStatusTicket", data);
            let email = this.$store.getters.localStorage.user.email;
            if (email !== data.user.email) {
              this.successToast(`Ticket has been update by ${data.user.name}`);
            }
          }
        });
      } else {
        this.socket?.emit("leave-app");
      }
    });

    this.socket?.on("get-status-online", (data) => {
      this.$store.dispatch("internalChat/setOnlineMember", data);
    });

    this.socket?.on("change-status-online", async (data) => {
      await this.$store.dispatch("internalChat/changeOnlineMember", data);
    });

    if (this.$store.getters.appId.decoded !== undefined) {
      await this.$store.dispatch("app/getAppData");
      const app = this.$store.getters["app/app"];
      if (!app.status) {
        this.errorToast("Your Subscription Expired");
      }
    }

    if (this.$store.getters.appId.decoded) {
      this.$store.dispatch("getNotificationSound");

      this.socket?.emit("join-app", this.$store.getters.appId.decoded);

      this.socket?.emit("get-room", this.$store.getters.appId.hashed);
      this.socket?.on("get-room", (data) => {
        this.$store.commit("internalChat/SET_ROOMS", data);
      });

      this.getGroup();

      this.socket?.on("get-group", (data) => {
        this.$store.commit("internalChat/SET_ROOM_GROUPS", data);
      });

      this.socket?.on("ChangeGroupData", (data) => {
        if (data.userId.find((r) => r === this.userId)) {
          this.getGroup(true);
        }
      });

      this.socket?.on("message", async (data) => {
        this.$store.dispatch("internalChat/newMessage", data);
      });

      this.socket?.on("UserSeen", async (data) => {
        if (data.id == this.$route.params.ticketId) {
          this.$store.dispatch("ticket/getVisitingAgent", data.seen);
        }
      });

      this.socket?.on("UpdateStatusTicket", async (data) => {
        if (data.ticket == this.$route.params.ticketId) {
          this.$store.dispatch("ticket/updateStatusTicket", data);
          let email = this.$store.getters.localStorage.user.email;
          if (email !== data.user.email) {
            this.successToast(`Ticket has been update by ${data.user.name}`);
          }
        }
      });

      this.socket?.on("NewMention", async (data) => {
        this.$store.dispatch("mention/handleNewMention", data.mention);
      });
    } else {
      this.socket?.emit("leave-app");
    }

    this.socket?.on("Test", (data) => {});

    this.socket?.on("LivechatRequest", (data) => {
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

      if (filter) {
        this.$store.dispatch("chat/liveChatRequest", data);
        this.$store.dispatch("agent/needUpdateAgent", true);
      }
    });

    this.socket?.on("LivechatResolved", (data) => {
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

      if (filter) {
        // if(this.assignedChannel.includes(data.room.channel.id)){
        this.$store.dispatch("chat/liveChatResolved", data);
        this.$store.dispatch("agent/needUpdateAgent", true);
      }
    });

    this.socket?.on("LivechatAssigned", (data) => {
      console.log("assigned data ", data);
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

      if (filter) {
        this.$store.dispatch("chat/livechatAssigned", data);
        this.$store.dispatch("agent/removeApproveAndWaitingAssignment", data);
        this.$store.dispatch("agent/needUpdateAgent", true);
      }
    });

    this.socket?.on("LivechatHandle", (data) => {
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

      if (filter) {
        this.$store.dispatch("chat/livechatHandle", data);
        this.$store.dispatch("agent/needUpdateAgent", true);
      }
    });

    this.socket?.on("LivechatCancel", (data) => {
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);

      if (filter) {
        this.$store.dispatch("chat/livechatCancel", data);
        this.$store.dispatch("agent/needUpdateAgent", true);
      }
    });

    this.socket?.on("RejectAssign", (data) => {
      this.$store.dispatch("agent/rejectAssign", data);
      if (
        this.$store.state.localStorage.decodedUserId ==
        data.room.livechat.handle_by
      ) {
        const message = `${data.agent.user.name} ${data.message}`;
        this.successToast(message);
      }
    });

    this.socket?.on("OnlineOffline", ({ current_status, user_id }) => {
      this.$store.dispatch("agent/updateAvailability", {
        current_status,
        user_id,
      });
      let data = { status: current_status, id: user_id };
      this.emitter.emit("updateOnlineAgentDashboard", data);
      if (user_id === this.$store.state.localStorage.decodedUserId) {
        this.$store.dispatch("agent/setOnline", current_status);
      }
    });

    this.socket?.on("CancelRequestLiveAgent", ({ total }) => {
      this.$store.dispatch("chat/updateUnservedRoom", total);
    });

    this.socket?.on("ApprovalAssignToAgent", (data) => {
      this.$store.dispatch("agent/approvalAgent", data);
    });

    this.socket?.on("ChangeMessage", (data) => {
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);
      if (filter) {
        this.$store.commit("chat/HANDLE_CHANGE_MESSAGE", data);
      }
    });
    this.socket?.on("NewMessage", (data) => {
      this.$store.dispatch("chat/filterRoom", {
        room: data.room,
        agent: this.agentRole,
        isn_count: true,
      });
      let filter = cloneDeep(this.$store.getters["chat/filterRoom"]);
      if (filter) {
        const bots = ["bot", "assistant"];
        if (
          data.message.messageable_type == "user_platform" &&
          data.user.id !== this.$store.state.localStorage.decodedUserId
        ) {
          // Notification.show({ ...data, notificationType: "chat-message" });
          this.$store.dispatch("chat/newMessage", data);
          // if (data.room.livechat !== null) {
          //   if (data.room.livechat.status == "request") {
          //     this.$store.dispatch("chat/liveChatUpdate", data);
          //   }
          // }
        } else if (bots.includes(data.message.messageable_type)) {
          // Notification.show({ ...data, notificationType: "chat-message" });
          this.$store.dispatch("chat/newMessage", data);
          // if (data.room.livechat !== null) {
          //   if (data.room.livechat.status == "request") {
          //     this.$store.dispatch("chat/liveChatUpdate", data);
          //   }
          // }
        } else if (data.message.messageable_type == "user") {
          if (this.isAgent) {
            if (data.room.livechat !== null) {
              if (data.room.livechat.status == "live") {
                if (
                  this.$store.state.localStorage.decodedUserId ==
                  data.room.livechat.handle_by
                ) {
                  Notification.show({
                    ...data,
                    notificationType: "chat-message",
                  });
                }
              }
            }
          } else {
            // Notification.show({
            //   ...data,
            //   notificationType: "chat-message",
            // });
          }
          this.$store.dispatch("chat/newMessage", data);

          // if (data.room.livechat !== null) {
          //   if (data.room.livechat.status == "request") {
          //     this.$store.dispatch("chat/liveChatUpdate", data);
          //   }
          // }
          if (this.$route.name == "omni.conversation.chat") {
            if (this.selectedRoom !== null) {
              if (this.selectedRoom.created_by.id == data.room.created_by.id) {
                return;
              }
            }
            // this.$receiveSound.play();

            const userId = this.$store.state.localStorage.decodedUserId;
            const isAgent = this.$store.state.accessibility.isAgent;

            const receiveAudio =
              this.receiveAudios.find(
                (r) => r.slug === this.notificationSound
              ) || this.receiveAudios[0];

            if (data.room.livechat) {
              if (
                !isAgent ||
                (isAgent && userId == data.room.livechat.handle_by)
              ) {
                receiveAudio.control.play();
              }
            }
          }
        }
      }
    });

    this.socket?.on("NewComment", (data) => {
      if (
        data.comment.userable_type == "user_platform" &&
        data.user.id !== this.$store.state.localStorage.decodedUserId
      ) {
        this.$store.dispatch("comment/newComment", data);
      } else if (data.comment.userable_type == "user") {
        this.$store.dispatch("comment/newComment", data);
      }
    });

    this.socket?.on("RequestLivecall", (data) => {
      this.$store.dispatch("chat/requestLivecall", data);
    });

    this.socket?.on("NewNotifTicket", (data) => {
      if (
        this.$store.getters.localStorage.decodedUserId == data.notif.user_id
      ) {
        this.$store.dispatch("app/newNotif", data.notif);
      }
    });

    this.socket?.on("NewMessageDiscussion", (data) => {
      if (
        data.message.user_id !==
          this.$store.getters.localStorage.decodedUserId &&
        data.message.ticket_id == this.$route.params.ticketId
      ) {
        this.$store.dispatch("ticket/addSendMessage", data);
        Notification.show({
          ...data,
          notificationType: "chat-message-discussion",
        });
      }
    });

    this.socket?.on("NewInquiries", (data) => {
      if (
        data.user !== this.$store.getters.localStorage.decodedUserId &&
        data.message.ticket_id == this.$route.params.ticketId
      ) {
        this.$store.dispatch("mail/addMail", data.message);
      }
    });

    this.socket?.on("NewMail", (data) => {
      if (this.$store.getters.appId.decoded == data.mail.app_id) {
        this.$store.dispatch("mail/getMailList");
        // this.emitter.emit("table::onRefresh"); // disable it for a while
      }
    });

    this.socket?.on("NewInquiries", (data) => {
      let user_id = this.$store.getters.localStorage.decodedUserId;

      function filterToAgentId(item) {
        return item.to_agent_id == user_id;
      }
      let filterUser = data.ticket.agent_ticket.filter(filterToAgentId);
      if (this.$store.getters.localStorage.decodedUserId == filterUser) {
        this.$store.dispatch("ticket/addCreatedTicket", data.ticket);
        this.emitter.emit("refreshTable");
      }
    });

    this.socket?.on("WhatsappLiteGetContact", (data) => {
      if (data.app_id === this.$store.getters.appId.decoded) {
        this.$store.dispatch("whatsappLite/setContacts", data);
      }
    });

    this.socket?.on("whatsapp_lite_qr", (data) => {
      this.$store.dispatch("whatsappLite/addQrCode", data);
    });

    this.socket?.on("whatsapp_lite_ready", (data) => {
      this.$store.dispatch("whatsappLite/setReady", data);
    });

    this.socket.on("NotifExport", (data) => {
      this.$store.dispatch("notification/newNotifExport", data.data);
      this.emitter.emit('notif/newNotif');
    });

    this.emitter.on("flash-success", (message) => {
      this.successToast(message);
    });
    this.emitter.on("flash-error", (message) => {
      this.errorToast(message);
    });

    // this.$store.dispatch("agent/getState");
  },
  methods: {
    async getGroup(refresh = false) {
      const groups = await this.$store.dispatch(
        "internalChat/getGroup",
        refresh
      );

      this.socket?.emit(
        "set-groups",
        groups.map((r) => r.id)
      );
    },
  },
};
</script>
<style>
.test{
  border:5px double red !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif !important;
}

.loading-wrapper {
  z-index: 99999;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.loading-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 63px;
  height: 63px;
  background: rgb(9, 43, 121);
  background: linear-gradient(
    328deg,
    rgba(9, 43, 121, 1) 21%,
    rgba(194, 76, 153, 1) 100%
  );
  border-radius: 16px;
}

.loading-item {
  width: 21px;
  height: 21px;
  display: block;
  float: left;
  background: #efeacc;
  animation: animate 2s ease-in infinite;
  opacity: 0.2;
}

.loading-item:nth-child(3) {
  animation-delay: 0.8s;
}

.loading-item:nth-child(2),
.loading-item:nth-child(6) {
  animation-delay: 0.6s;
}

.loading-item:nth-child(1) {
  border-radius: 16px 0px 0px 0px;
}

.loading-item:nth-child(3) {
  border-radius: 0px 16px 0px 0px;
}

.loading-item:nth-child(7) {
  border-radius: 0px 0px 0px 16px;
}

.loading-item:nth-child(9) {
  border-radius: 0px 0px 16px 0px;
}

.loading-item:nth-child(1),
.loading-item:nth-child(5),
.loading-item:nth-child(9) {
  animation-delay: 0.4s;
}

.loading-item:nth-child(4),
.loading-item:nth-child(8) {
  animation-delay: 0.2s;
}

.loading-item:nth-child(7) {
  animation-delay: 0s;
}

@keyframes animate {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

input.form-control {
  padding: 0.6em 1em;
  min-height: 40px;
  border: 1px solid #eaeaea !important;
  border-radius: 5px !important;
}

input.form-control[type="text"]:focus {
  border: 1px solid #94c2fb !important;
  box-shadow: 0 0 1px 3px #b8dbff !important;
  outline: none;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}

.modal-dialog .rounded-end,
.rounded-top {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.modal-dialog .rounded-top {
  border-top-left-radius: 0.5rem !important;
}

.modal-dialog .modal-content {
  border-radius: 0.5rem;
}

.vs--searchable .vs__dropdown-toggle {
  padding: 0.2em 1em !important;
  padding-left: 0.3em !important;
  cursor: text;
}

.vs__dropdown-toggle {
  border: 1px solid #eaeaea !important;
}
</style>
